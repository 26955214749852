/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { NotificationsHistoryTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type NotificationsHistoryTableDataState = {
  pageData: Undefined<NotificationsHistoryTablePageData>;
};

const initialState: NotificationsHistoryTableDataState = {
  pageData: undefined,
};

const notificationsHistorySlice = createSlice({
  name: "notifications-history",
  initialState,

  reducers: {
    setNotificationsHistoryTableData: (state, action: PayloadAction<NotificationsHistoryTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setNotificationsHistoryTableData } = notificationsHistorySlice.actions;

export const { reducer: notificationsHistoryReducer } = notificationsHistorySlice;
