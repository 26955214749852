/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { CreateServicePageModals, CreateServicePageStateStored, OpeningHoursDataStored } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CreateServicePageStateStored = {
  currentModal: null,
  openingHoursData: {
    MONDAY: null,
    TUESDAY: null,
    WEDNESDAY: null,
    THURSDAY: null,
    FRIDAY: null,
    SATURDAY: null,
    SUNDAY: null,
  },
};

const createServicePageStateSlice = createSlice({
  name: "create-service-page",
  initialState,
  reducers: {
    setCurrentModal: (state, action: PayloadAction<Nullable<CreateServicePageModals>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setOpeningHours: (state, action: PayloadAction<OpeningHoursDataStored>) => {
      const draft = state;
      draft.openingHoursData = action.payload;
    },
    closeModals: (state) => {
      const draft = state;
      draft.currentModal = null;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentModal, closeModals, setOpeningHours, resetState } = createServicePageStateSlice.actions;

export const { reducer: createServicePageStateReducer } = createServicePageStateSlice;
