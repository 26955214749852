import { setAxiosLanguageHeader } from "./axiosInstance";
import { AcceptedLanguages } from "./constants";
import { storePreferredLanguage } from "./localstorage-utils";

import dayjs from "dayjs";
import en from "dayjs/locale/en"; // path must match with `i18n.language`
import fr from "dayjs/locale/fr"; // path must match with `i18n.language`

const generateLocal = (language: AcceptedLanguages): ILocale | string => {
  const formats = {
    LT: "h:mm A",
    LTS: "h:mm:ss A",
    // eslint-disable-next-line id-length
    L: language === AcceptedLanguages.en ? "MM/DD/YYYY" : "DD/MM/YYYY",
    LL: "MMMM D, YYYY",
    LLL: "MMMM D, YYYY h:mm A",
    LLLL: "dddd, MMMM D, YYYY h:mm A",
  };

  if (language === AcceptedLanguages.fr) {
    return {
      ...fr,
      weekStart: 0,
      formats,
    };
  }
  return {
    ...en,
    formats,
  };
};

export const translateTheApp = (language: AcceptedLanguages): void => {
  storePreferredLanguage(language);
  setAxiosLanguageHeader(language);

  dayjs.locale(generateLocal(language));
};
