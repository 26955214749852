/* eslint-disable no-param-reassign */

import type { CategoriesPageState } from "./types";
import { CategoriesPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CategoriesPageState = {
  currentTab: CategoriesPageTabsEnum.INCIDENTS,
};

const categoriesPageSlice = createSlice({
  name: "categories-page",
  initialState,

  reducers: {
    setCurrentTab: (state, action: PayloadAction<CategoriesPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
  },
});

export const { setCurrentTab } = categoriesPageSlice.actions;

export const { reducer: categoriesPageReducer } = categoriesPageSlice;
