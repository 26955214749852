/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { ServicesTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type ServicesTableDataState = {
  pageData: Undefined<ServicesTablePageData>;
};

const initialState: ServicesTableDataState = {
  pageData: undefined,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,

  reducers: {
    setServicesTableData: (state, action: PayloadAction<ServicesTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setServicesTableData } = servicesSlice.actions;

export const { reducer: servicesReducer } = servicesSlice;
