/* eslint-disable no-param-reassign */

import type { ServiceTypeFilter } from "~api/hotels/types";
import type { Nullable } from "~utils/types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { ServicesFilters, ServicesFiltersState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: ServicesFilters = {
  category: null,
  type: null,
  isArchived: null,
};

const initialState: ServicesFiltersState = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const servicesFiltersSlice = createSlice({
  name: "service-filters",
  initialState,

  reducers: {
    applyFilters: (state) => {
      const draft = state;
      draft.appliedFilters = draft.filters;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setCategory: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.filters.category = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setType: (state, action: PayloadAction<ServiceTypeFilter>) => {
      const draft = state;
      draft.filters.type = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setIsArchived: (state, action: PayloadAction<Nullable<boolean>>) => {
      const draft = state;
      draft.filters.isArchived = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const { applyFilters, setCategory, setType, setIsArchived, resetFilters } = servicesFiltersSlice.actions;

export const { reducer: servicesFiltersReducer } = servicesFiltersSlice;
