import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";
import { isDefined } from "~utils/boolean-utils";

import type { TeamOperationsFilters, TeamOperationsFiltersState } from "./types";

export const useSelectTeamOperationsFilters = (): TeamOperationsFiltersState => {
  return useAppSelector((state: RootState) => state.teamOperationsFilters);
};

export const getNumberOfAppliedFilters = (filters: TeamOperationsFilters): number => {
  return (
    Number(isDefined(filters.team)) +
    Number(isDefined(filters.employee)) +
    Number(isDefined(filters.isAutoQualify)) +
    Number(isDefined(filters.isAutoClose))
  );
};

export const useGetNumberOfAppliedTeamOperationsFilters = (): number => {
  return useAppSelector((state: RootState) => state.teamOperationsFilters.numberOfAppliedFilters);
};
