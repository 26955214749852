/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { TeamsTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TeamsTableDataState = {
  pageData: Undefined<TeamsTablePageData>;
};

const initialState: TeamsTableDataState = {
  pageData: undefined,
};

const TeamsSlice = createSlice({
  name: "Teams",
  initialState,

  reducers: {
    setTeamsTableData: (state, action: PayloadAction<TeamsTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setTeamsTableData } = TeamsSlice.actions;

export const { reducer: TeamsReducer } = TeamsSlice;
