import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";
import { isDefined } from "~utils/boolean-utils";

import type { NotificationsHistoryFiltersStateStored, NotificationsHistoryFiltersStored } from "./types";

export const useNotificationsHistoryFiltersSelector = (): NotificationsHistoryFiltersStateStored => {
  return useAppSelector((state: RootState) => state.notificationsHistoryFilters);
};

export const getNumberOfAppliedFilters = (filters: NotificationsHistoryFiltersStored): number => {
  return (
    Number(isDefined(filters.platform)) +
    Number(isDefined(filters.reason)) +
    Number(isDefined(filters.receiverCategory)) +
    Number(isDefined(filters.startDate)) +
    Number(isDefined(filters.endDate))
  );
};

export const useGetNumberOfAppliedNotificationsHistoryFilters = (): number => {
  return useAppSelector((state: RootState) => state.notificationsHistoryFilters.numberOfAppliedFilters);
};
