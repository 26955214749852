import type { ServiceDetails, ServiceTranslation } from "~utils/types/global-types";

import type { ServiceDetailsModalsEnum, ServiceDetailsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: ServiceDetailsState = {
  currentModal: null,
  service: null,
  translation: null,
};

const serviceDetailsSlice = createSlice({
  name: "service-details",
  initialState,

  reducers: {
    setCurrentModal: (state, action: PayloadAction<ServiceDetailsModalsEnum>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setService: (state, action: PayloadAction<ServiceDetails>) => {
      const draft = state;
      draft.service = action.payload;
    },
    setTranslation: (state, action: PayloadAction<ServiceTranslation>) => {
      const draft = state;
      draft.translation = action.payload;
    },
    closeModals: (state) => {
      const draft = state;
      draft.currentModal = null;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentModal, setService, setTranslation, closeModals, reset } = serviceDetailsSlice.actions;

export const { reducer: serviceDetailsReducer } = serviceDetailsSlice;
