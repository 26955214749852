import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";
import { isDefined } from "~utils/boolean-utils";

import type { OperationsFilters, OperationsFiltersState } from "./types";

export const useSelectOperationsFilters = (): OperationsFiltersState => {
  return useAppSelector((state: RootState) => state.operationsFilters);
};

export const getNumberOfAppliedFilters = (filters: OperationsFilters): number => {
  return (
    Number(Object.values(filters.actionType).includes(true)) +
    Number(Object.values(filters.status).includes(true)) +
    Number(isDefined(filters.scope))
  );
};

export const useGetNumberOfAppliedOperationsFilters = (): number => {
  return useAppSelector((state: RootState) => state.operationsFilters.numberOfAppliedFilters);
};
