import type { Callback, Consumer, Nullable } from "~utils/types";

export enum ForgotPasswordPageSteps {
  USER_IDENTIFICATION = "user_identification",
  VALIDATION_CODE = "validation_code",
}

export enum ForgotPasswordPageModals {
  CODE_NOT_RECEIVED = "code_not_received",
}

export type ForgotPasswordPageState = {
  currentStep: ForgotPasswordPageSteps;
  username: string;
  currentModal: Nullable<ForgotPasswordPageModals>;
  hotelPhone: string;
};

export interface IUseForgotPasswordPage {
  setUsername: Consumer<string>;
  setCurrentStep: Consumer<ForgotPasswordPageSteps>;
  setCurrentModal: Consumer<Nullable<ForgotPasswordPageModals>>;
  setHotelPhone: Consumer<string>;
  reset: Callback;
}
