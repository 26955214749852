/* eslint-disable no-param-reassign */

import { getNextImprotGuestStep } from "./helpers";
import type { GuestRowResult, ImportGuestMapping, ImportGuestsData, ImportGuestState, OverviewResults } from "./types";
import { ImportGuestSteps } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ImportGuestState = {
  importedData: null,
  mapping: {
    firstName: null,
    lastName: null,
    phone: null,
    preferredLanguage: null,
    room: null,
    stayDateEnd: null,
    stayDateStart: null,
  },
  steps: {
    [ImportGuestSteps.LOAD_FILE]: {
      isOpen: true,
    },
    [ImportGuestSteps.DATA_MAPPING]: {
      isOpen: false,
    },
    [ImportGuestSteps.DATA_MAPPING_RESULTS]: {
      isOpen: false,
    },
    [ImportGuestSteps.GUESTS_IMPORT_RESULTS]: {
      isOpen: false,
    },
  },
  rowsResults: [],
  resultsOverview: {
    acceptedRows: 0,
    rejectedRows: 0,
    totalRows: 0,
  },
  guestsImportIsDone: false,
  loadedFileName: null,
};

const importGuestsPageSlice = createSlice({
  name: "import-guests-page",
  initialState,

  reducers: {
    setImportedData: (state, action: PayloadAction<ImportGuestsData>) => {
      const draft = state;
      draft.importedData = action.payload;
    },
    toggleStepAccordion: (state, action: PayloadAction<ImportGuestSteps>) => {
      const draft = state;
      const isExpanded = draft.steps[action.payload].isOpen;
      draft.steps[action.payload].isOpen = !isExpanded;
    },
    openNextStepAccordion: (state, action: PayloadAction<ImportGuestSteps>) => {
      const draft = state;
      const nextStep = getNextImprotGuestStep(action.payload);
      draft.steps[action.payload].isOpen = false;
      draft.steps[nextStep].isOpen = true;
    },
    updateMapping: (state, action: PayloadAction<ImportGuestMapping>) => {
      const draft = state;
      draft.mapping = action.payload;
    },
    updateRowsResults: (state, action: PayloadAction<GuestRowResult[]>) => {
      const draft = state;
      draft.rowsResults = [...action.payload];
    },
    updateResultsOverview: (state, action: PayloadAction<OverviewResults>) => {
      const draft = state;
      draft.resultsOverview = { ...action.payload };
    },
    incrementRejectedRowsNumber: (state) => {
      const draft = state;
      draft.resultsOverview.rejectedRows++;
    },
    incrementAcceptedRowsNumber: (state) => {
      const draft = state;
      draft.resultsOverview.acceptedRows++;
    },
    addRowResult: (state, action: PayloadAction<GuestRowResult>) => {
      const draft = state;
      draft.rowsResults.push(action.payload);
    },
    updateGuestsImportIsDone: (state, action: PayloadAction<boolean>) => {
      const draft = state;
      draft.guestsImportIsDone = action.payload;
    },
    setLoadedFileName: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.loadedFileName = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const {
  setImportedData,
  reset,
  toggleStepAccordion,
  openNextStepAccordion,
  updateMapping,
  updateResultsOverview,
  updateRowsResults,
  incrementRejectedRowsNumber,
  incrementAcceptedRowsNumber,
  addRowResult,
  updateGuestsImportIsDone,
  setLoadedFileName,
} = importGuestsPageSlice.actions;

export const { reducer: importGuestsPageReducer } = importGuestsPageSlice;
