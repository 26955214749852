/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { ZonesPageModalsEnum, ZonesPageState } from "./types";
import { ZonesPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ZonesPageState = {
  currentTab: ZonesPageTabsEnum.ZONES,
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedRowId: null,
};

const zonesPageSlice = createSlice({
  name: "zones-page",
  initialState,

  reducers: {
    setCurrentTab: (state, action: PayloadAction<ZonesPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<ZonesPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setSelectedRowId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedRowId = action.payload;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentTab, setCurrentModal, setSelectedRowId, setDetailsButtonAnchorId, resetState } =
  zonesPageSlice.actions;

export const { reducer: zonesPageReducer } = zonesPageSlice;
