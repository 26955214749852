/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { GuestsTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type GuestsTableDataState = {
  pageData: Undefined<GuestsTablePageData>;
};

const initialState: GuestsTableDataState = {
  pageData: undefined,
};

const guestsSlice = createSlice({
  name: "guests",
  initialState,

  reducers: {
    setGuestsTableData: (state, action: PayloadAction<GuestsTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setGuestsTableData } = guestsSlice.actions;

export const { reducer: guestsReducer } = guestsSlice;
