import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";
import { isDefined } from "~utils/boolean-utils";

import type { EmployeesFiltersStateStored, EmployeesFiltersStored } from "./types";

export const useSelectEmployeesFilters = (): EmployeesFiltersStateStored => {
  return useAppSelector((state: RootState) => state.employeesFilters);
};

export const getNumberOfAppliedFilters = (filters: EmployeesFiltersStored): number => {
  const { team, maxLastLoginDate, minLastLoginDate, role, isSupervisor } = filters;

  return (
    Number(isDefined(team)) +
    Number(isDefined(role)) +
    Number(isDefined(minLastLoginDate)) +
    Number(isDefined(maxLastLoginDate)) +
    Number(isDefined(isSupervisor))
  );
};

export const useGetNumberOfAppliedEmployeesFilters = (): number => {
  return useAppSelector((state: RootState) => state.employeesFilters.numberOfAppliedFilters);
};
