import { useEffect } from "react";

import { useSelectCurrentUser } from "~store/current-user/selector";
import { isDefined } from "~utils/boolean-utils";
import { voidFunction } from "~utils/functions";
import { getPreferredLanguage } from "~utils/localstorage-utils";

import i18n from "./i18n";

import { changeLanguage } from "i18next";
import { I18nextProvider } from "react-i18next";

type Props = {
  children?: React.ReactNode;
};
export const I18nProvider: React.FC<Props> = ({ children }) => {
  const currentUser = useSelectCurrentUser();

  useEffect(() => {
    const userPreferredLanguage = currentUser?.hotel.defaultLanguage ?? getPreferredLanguage();

    if (isDefined(userPreferredLanguage)) {
      changeLanguage(userPreferredLanguage).catch(voidFunction);
    }
  });
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
