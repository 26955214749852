import { useCloseSnackBar } from "~store/snack-bar/hooks";
import { useGetSnackBarState } from "~store/snack-bar/selector";

import { Alert, Snackbar } from "@mui/material";
import type { FC } from "react";

export const SnackBarProvider: FC = () => {
  const { isOpen, message, severity, autoHideDuration } = useGetSnackBarState();
  const { closeSnackBar } = useCloseSnackBar();

  const handleClose = (_event?: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackBar();
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
