import { isDefined } from "~utils/boolean-utils";

import type { GuestRowData, ImportGuestMapping } from "./types";
import { ImportGuestSteps, OptionalFields } from "./types";

export const getNextImprotGuestStep = (currentValue: ImportGuestSteps): ImportGuestSteps => {
  const enumValues = Object.values(ImportGuestSteps);
  const currentIndex = enumValues.indexOf(currentValue);

  if (currentIndex !== -1 && currentIndex < enumValues.length - 1) {
    return enumValues[currentIndex + 1] as never;
  }

  return ImportGuestSteps.LOAD_FILE;
};

export const isOptionalField = (fieldName: keyof GuestRowData): boolean => {
  return OptionalFields.includes(fieldName);
};

export const isMappingValid = (mapping: ImportGuestMapping): boolean => {
  return Object.keys(mapping)
    .filter((fieldName) => !isOptionalField(fieldName as keyof GuestRowData))
    .every((key) => isDefined(mapping[key as keyof GuestRowData]));
};

export const isMappingDone = (mapping: ImportGuestMapping): boolean => {
  return isMappingValid(mapping);
};
