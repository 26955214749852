import type { Callback, Consumer, Nullable } from "~utils/types";

import type { Dayjs } from "dayjs";

export enum ReportingPageTabsEnum {
  OPERATION_PERFORMANCE = "operation_performance",
  GUEST = "guest",
  INCIDENT = "incident",
}

type CommonState = {
  currentTab: Nullable<ReportingPageTabsEnum>;
};
export type ReportingPageState = CommonState & {
  startDate: Date;
  endDate: Date;
};

export type ReportingPageStateStored = CommonState & {
  startDate: string;
  endDate: string;
};

export type UseReportingPageStateReturnType = {
  setCurrentTab: Consumer<ReportingPageTabsEnum>;
  setStartDate: Consumer<Dayjs>;
  setEndDate: Consumer<Dayjs>;
  resetState: Callback;
};
