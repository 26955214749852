import type { ImageViewState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ImageViewState = {
  images: undefined,
  isOpen: false,
  startIndex: 0,
};

const imageViewerSlice = createSlice({
  name: "image-viewer",
  initialState,
  reducers: {
    setImages: (state, action: PayloadAction<string[]>) => {
      const draft = state;
      draft.images = action.payload;
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      const draft = state;
      draft.isOpen = action.payload;
    },
    setStartIndex: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.startIndex = action.payload;
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const { setImages, setIsOpen, setStartIndex, resetState } = imageViewerSlice.actions;

export const { reducer: imageViewerReducer } = imageViewerSlice;
