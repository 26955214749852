import type { Consumer, Nullable } from "~utils/types";

export enum TasksPageTabsEnum {
  TODO_TASKS = "todo_tasks",
  FINISHED_TASKS = "finished_tasks",
  CREATED_OPERATIONS = "created_operations",
}

export type TasksPageState = {
  currentTab: Nullable<TasksPageTabsEnum>;
};

export type UseTasksPageStateReturnType = {
  setCurrentTab: Consumer<TasksPageTabsEnum>;
};
