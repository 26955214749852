/* eslint-disable no-param-reassign */

import type { GuestTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type GuestTableSearchParamsState = {
  params: GuestTableSearchParams;
};
const initialState: GuestTableSearchParamsState = {
  params: {
    page: 1,
    limit: 10,
    keyword: null,
  },
};

const guestSearchParamsSlice = createSlice({
  name: "guest-search-params",
  initialState,

  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.params.keyword = action.payload;
    },
    resetPageNumber: (state) => {
      const draft = state;
      draft.params.page = initialState.params.page;
    },
    resetParams: () => {
      return initialState;
    },
  },
});

export const { setPageNumber, setPageLimit, setKeyword, resetPageNumber, resetParams } = guestSearchParamsSlice.actions;

export const { reducer: guestSearchParamsReducer } = guestSearchParamsSlice;
