import { voidFunction } from "~utils/functions";

import { getConnectedUser, updateUserPassword } from "./repository";
import type { UpdateUserPasswordDTO, UseGetConnectedUserType, UseUpdateUserPasswordType } from "./types";

import { useMutation, useQuery } from "react-query";

export const useGetConnectedUser: UseGetConnectedUserType = (onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useQuery({
    queryKey: ["connected-user"],
    queryFn: async () => getConnectedUser(),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
    enabled: false,
  });
};

export const useUpdateUserPassword: UseUpdateUserPasswordType = (
  onSuccessFn = voidFunction,
  onErrorFn = voidFunction,
) => {
  return useMutation({
    mutationKey: ["connected-user"],
    mutationFn: async (dto: UpdateUserPasswordDTO) => updateUserPassword(dto),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};
