import type { ReportingPageStateStored } from "./types";
import { ReportingPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const initialState: ReportingPageStateStored = {
  currentTab: ReportingPageTabsEnum.OPERATION_PERFORMANCE,
  startDate: dayjs(new Date()).subtract(7, "day").toISOString(),
  endDate: dayjs(new Date()).toISOString(),
};

const reportingPageSlice = createSlice({
  name: "reporting-page",
  initialState,

  reducers: {
    setCurrentTab: (state, action: PayloadAction<ReportingPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.endDate = action.payload;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentTab, setStartDate, setEndDate, resetState } = reportingPageSlice.actions;

export const { reducer: reportingPageReducer } = reportingPageSlice;
