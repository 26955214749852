import type { Employee } from "~utils/types/global-types";

import type { EmployeeDetailsModalsEnum, EmployeeDetailsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: EmployeeDetailsState = {
  currentModal: null,
  employee: null,
};

const employeeDetailsSlice = createSlice({
  name: "employee-details-modals",
  initialState,

  reducers: {
    setCurrentModal: (state, action: PayloadAction<EmployeeDetailsModalsEnum>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setEmployee: (state, action: PayloadAction<Employee>) => {
      const draft = state;
      draft.employee = action.payload;
    },
    closeModals: (state) => {
      const draft = state;
      draft.currentModal = null;
    },
  },
});

export const { setCurrentModal, setEmployee, closeModals } = employeeDetailsSlice.actions;

export const { reducer: employeeDetailsReducer } = employeeDetailsSlice;
