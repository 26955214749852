/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { SubLocationsTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type SubLocationsTableDataState = {
  pageData: Undefined<SubLocationsTablePageData>;
};

const initialState: SubLocationsTableDataState = {
  pageData: undefined,
};

const subLocationsSlice = createSlice({
  name: "sub-locations",
  initialState,

  reducers: {
    setSubLocationsTableData: (state, action: PayloadAction<SubLocationsTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setSubLocationsTableData } = subLocationsSlice.actions;

export const { reducer: subLocationsReducer } = subLocationsSlice;
