/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { EmployeesFiltersStateStored, EmployeesFiltersStored, RoleFilter, TeamFilter } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: EmployeesFiltersStored = {
  team: null,
  role: null,
  minLastLoginDate: null,
  maxLastLoginDate: null,
  isSupervisor: null,
};

const initialState: EmployeesFiltersStateStored = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const employeesFiltersSlice = createSlice({
  name: "employees-filters",
  initialState,

  reducers: {
    setTeamFilter: (state, action: PayloadAction<TeamFilter>) => {
      const draft = state;
      draft.filters.team = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setRoleFilter: (state, action: PayloadAction<RoleFilter>) => {
      const draft = state;
      draft.filters.role = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setMinLastLogin: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.minLastLoginDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setMaxLastLogin: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.maxLastLoginDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setIsSupervisor: (state, action: PayloadAction<Nullable<boolean>>) => {
      const draft = state;
      draft.filters.isSupervisor = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const {
  setTeamFilter,
  setRoleFilter,
  setMinLastLogin,
  setMaxLastLogin,
  setIsSupervisor,
  applyFilters,
  resetFilters,
} = employeesFiltersSlice.actions;

export const { reducer: employeesFiltersReducer } = employeesFiltersSlice;
