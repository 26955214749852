import { useEffect } from "react";

import { useGetHotel } from "~api/hotels/hooks";
import { useGetConnectedUser } from "~api/users/hooks";
import { useAppDispatch } from "~store/hooks";
import { isEmpty } from "~utils/boolean-utils";
import { setAppTimezone } from "~utils/date-utils";
import { voidFunction } from "~utils/functions";
import { translateTheApp } from "~utils/translation-utils";
import type { Hotel, HotelPermissions, User, UserPermissions } from "~utils/types/global-types";
import { EstablishmentTypeEnum } from "~utils/types/global-types";

import { isDefined, isNullOrUndefined } from "./../../utils/boolean-utils";
import { useSelectCurrentUser } from "./selector";
import { removeCurrentUser, setCurrentUser, setUserHotel } from "./slice";
import type { CurrentUser, UseCurrentUserReturnType, UseGetCurrentUserReturnType } from "./types";

export const useCurrentUser = (): UseCurrentUserReturnType => {
  const dispatch = useAppDispatch();
  const currenUser = useSelectCurrentUser();

  const setCurrentUserFn = (user: CurrentUser) => {
    dispatch(setCurrentUser(user));
  };

  const setUserHotelFn = (hotel: Hotel) => {
    if (isNullOrUndefined(currenUser)) {
      return;
    }
    dispatch(setUserHotel(hotel));
  };

  const removeCurrentUserFn = () => {
    dispatch(removeCurrentUser());
  };

  const userHasPermissionFn = (requiredPermissionCode: UserPermissions): boolean => {
    return (
      isDefined(currenUser) &&
      currenUser.role.permissions.some((permission) => permission.code === requiredPermissionCode)
    );
  };

  const userHasAnyOfThesePermissionsFn = (permissions: UserPermissions[]): boolean => {
    if (isNullOrUndefined(currenUser)) {
      return false;
    }
    if (isEmpty(permissions)) {
      return true;
    }

    const userPermissionsCodes = currenUser.role.permissions.map((permission) => permission.code);

    return permissions.some((permission) => userPermissionsCodes.includes(permission));
  };

  const userHasAllOfThesePermissionsFn = (permissions: UserPermissions[]): boolean => {
    if (isNullOrUndefined(currenUser)) {
      return false;
    }
    if (isEmpty(permissions)) {
      return true;
    }
    const userPermissionsCodes = currenUser.role.permissions.map((permission) => permission.code);

    return permissions.every((permission) => userPermissionsCodes.includes(permission));
  };

  const userHotelHasPermissionFn = (requiredPermissionCode: HotelPermissions): boolean => {
    return (
      (isDefined(currenUser?.hotel) &&
        currenUser?.hotel.permissions.some((permission) => permission.code === requiredPermissionCode)) ??
      false
    );
  };

  const userHotelHasAnyOfThesePermissionFn = (permissions: HotelPermissions[]): boolean => {
    if (isNullOrUndefined(currenUser)) {
      return false;
    }
    if (isEmpty(permissions)) {
      return true;
    }
    const hotelPermissionCodes = currenUser.hotel.permissions.map((permission) => permission.code);

    return permissions.some((permission) => hotelPermissionCodes.includes(permission));
  };

  const userHotelIsVisitorOnlyFn = (): boolean => {
    return (isDefined(currenUser?.hotel) && currenUser?.hotel.visitorOnly) ?? false;
  };

  const userEstablishementIsHotelFn = (): boolean => {
    return isDefined(currenUser?.hotel) && currenUser?.hotel.type === EstablishmentTypeEnum.HOTEL;
  };
  const { refetch: refetchUserHotelFn } = useGetHotel({
    id: currenUser?.hotel.id ?? "",
    isEnabled: false,
    onSuccessFn: setUserHotelFn,
  });

  return {
    setCurrentUser: setCurrentUserFn,
    setUserHotel: setUserHotelFn,
    removeCurrentUser: removeCurrentUserFn,
    userHasPermission: userHasPermissionFn,
    userHasAnyOfThesePermissions: userHasAnyOfThesePermissionsFn,
    userHasAllOfThesePermissions: userHasAllOfThesePermissionsFn,
    userHotelHasPermission: userHotelHasPermissionFn,
    userHotelIsVisitorOnly: userHotelIsVisitorOnlyFn,
    userEstablishementIsHotel: userEstablishementIsHotelFn,
    userHotelHasAnyOfThesePermission: userHotelHasAnyOfThesePermissionFn,
    refetchUserHotel: () => {
      refetchUserHotelFn().catch(voidFunction);
    },
  };
};

export const useGetCurrentUser = (): UseGetCurrentUserReturnType => {
  const user = useSelectCurrentUser();
  const { setCurrentUser: setUser } = useCurrentUser();

  const onSuccessFn = (currentUser: User) => {
    const { defaultLanguage, defaultTimezone } = currentUser.hotel;

    translateTheApp(defaultLanguage);
    setAppTimezone(defaultTimezone);

    setUser(currentUser);
  };

  const onErrorFn = voidFunction;
  const { isLoading, isError, refetch } = useGetConnectedUser(onSuccessFn, onErrorFn);

  useEffect(() => {
    if (user === null) {
      refetch().finally(voidFunction);
    }
  }, [refetch, user]);

  return { user, isLoading, isError };
};
