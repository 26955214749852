import type { Callback, Consumer, Nullable } from "~utils/types";
import type { Guest, HotelRoom } from "~utils/types/global-types";

export enum TaskTypeEnum {
  BOOKING = "booking",
  ORDER = "order",
  REQUEST = "request",
  INTERNAL_TASK = "internal_task",
  INCIDENT = "incident",
}

export enum NewTaskPageModalsEnum {
  CREATE_GUEST = "create_guest",
  CREATE_STAY = "create_stay",
}

export type NewTaskPageModalsState = {
  taskCreated: boolean;
  currentModal: Nullable<NewTaskPageModalsEnum>;
  currentTaskType: TaskTypeEnum;
  guestSearchKeyword: Nullable<string>;
  currentGuest: Nullable<Guest>;
  currentRoom: Nullable<HotelRoom>;
};

export type UseNewTaskPageState = {
  setTaskCreated: Consumer<boolean>;
  setCurrentModal: Consumer<NewTaskPageModalsEnum>;
  setCurrentTaskType: Consumer<TaskTypeEnum>;
  setGuestSearchKeyword: Consumer<string>;
  setCurrentGuest: Consumer<Nullable<Guest>>;
  setCurrentRoom: Consumer<Nullable<HotelRoom>>;
  closeModals: Callback;
  resetState: Callback;
};
