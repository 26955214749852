import type { Consumer } from "~utils/types";
import type { NotificationChannel, NotificationPlatformEnum, Pageable } from "~utils/types/global-types";

export enum NotificationsPageTabsEnum {
  TEAM_CHANNELS = "team_channels",
  HOTEL_CHANNELS = "hotel_channels",
  NOTIFICATIONS_HISTORY = "notifications_history",
}

export interface NotificationChannelTableData {
  id: string;
  team: string;
  platform: NotificationPlatformEnum;
  isActive: boolean;
  value: string;
  createdAt: string;
}

export type NotificationChannelsTablePageData = Pageable<NotificationChannelTableData>;

export type IUseGetNotificationChannelsResponseToNotificationChannelsTablePageDataMapper = () => {
  mapGetNotificationChannelsResponseToNotificationChannelsTablePageData: (
    response: Pageable<NotificationChannel>,
  ) => NotificationChannelsTablePageData;
};

export type IUseNotificationChannelsTablePageData = () => {
  updateNotificationChannelTableData: (id: string, value: NotificationChannelTableData) => void;
};

export type IUseNotificationChannels = () => {
  setCurrentTab: Consumer<NotificationsPageTabsEnum>;
};
