import { setPageNumber } from "~store/guests-search-params/slice";
import { useAppDispatch } from "~store/hooks";
import { toDateIsoString } from "~utils/date-utils";
import type { Nullable } from "~utils/types";

import { mapGuestsFiltersStoredToState } from "./mappers";
import { useSelectGuestsFilters } from "./selectors";
import {
  applyFilters,
  resetFilters,
  setGuestNeedType,
  setIsIdentified,
  setRoom,
  setStayEndsAt,
  setStayStartsAt,
} from "./slice";
import type { GuestNeedType, GuestsFiltersState, UseGuestsFiltersReturnType } from "./types";

export const useGuestsFilters = (): UseGuestsFiltersReturnType => {
  const dispatch = useAppDispatch();

  const setGuestNeedTypeFn = (type: GuestNeedType) => {
    dispatch(setGuestNeedType(type));
  };

  const setRoomFn = (room: Nullable<string>) => {
    dispatch(setRoom(room));
  };

  const setStayStartsAtFn = (date: Nullable<Date>) => {
    dispatch(setStayStartsAt(toDateIsoString(date)));
  };

  const setStayEndsAtFn = (date: Nullable<Date>) => {
    dispatch(setStayEndsAt(toDateIsoString(date)));
  };

  const setIsIdentifiedFn = (value: Nullable<boolean>) => {
    dispatch(setIsIdentified(value));
  };

  const applyFiltersFn = () => {
    dispatch(applyFilters());
    dispatch(setPageNumber(1));
  };

  const resetFiltersFn = () => {
    dispatch(resetFilters());
  };

  return {
    applyFilters: applyFiltersFn,
    resetFilters: resetFiltersFn,
    setGuestNeedType: setGuestNeedTypeFn,
    setStayStartsAt: setStayStartsAtFn,
    setStayEndsAt: setStayEndsAtFn,
    setRoom: setRoomFn,
    setIsIdentified: setIsIdentifiedFn,
  };
};

export const useGetGuestsFilters = (): GuestsFiltersState => {
  const guestsFilters = useSelectGuestsFilters();
  return mapGuestsFiltersStoredToState(guestsFilters);
};
