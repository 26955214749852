/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { GuestNeedsFiltersStateStored, GuestNeedsFiltersStored, GuestNeedStatus, GuestNeedType } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: GuestNeedsFiltersStored = {
  type: { BOOKING: false, ORDER: false, REQUEST: false },
  status: null,
  minDate: null,
  maxDate: null,
};

const initialState: GuestNeedsFiltersStateStored = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const guestNeedsFiltersSlice = createSlice({
  name: "guetNeedsFilters",
  initialState,

  reducers: {
    setType: (state, action: PayloadAction<GuestNeedType>) => {
      const draft = state;
      const isChecked = draft.filters.type[action.payload];
      draft.filters.type[action.payload] = !isChecked;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setStatus: (state, action: PayloadAction<GuestNeedStatus>) => {
      const draft = state;
      draft.filters.status = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setMinDate: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.minDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setMaxDate: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.maxDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const { setType, setStatus, setMinDate, setMaxDate, applyFilters, resetFilters } =
  guestNeedsFiltersSlice.actions;

export const { reducer: guestNeedsFiltersReducer } = guestNeedsFiltersSlice;
