/* eslint-disable no-param-reassign */

import type { Task } from "~utils/types/global-types";

import type { TaskDetailsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: TaskDetailsState = {
  value: undefined,
};

const taskDetailsSlice = createSlice({
  name: "task-details",
  initialState,

  reducers: {
    setTaskDetails: (state, action: PayloadAction<Task>) => {
      state.value = action.payload;
    },
    resetTaskDetails: (state) => {
      state.value = undefined;
    },
  },
});

export const { setTaskDetails, resetTaskDetails } = taskDetailsSlice.actions;

export const { reducer: taskDetailsReducer } = taskDetailsSlice;
