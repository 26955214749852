/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { FaqQuestionsPageModalsEnum, FaqQuestionsPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: FaqQuestionsPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedFaqQuestionId: null,
};

const faqQuestionsPageModalsSlice = createSlice({
  name: "faq-questions-page-modals",
  initialState,
  reducers: {
    setCurrentModal: (state, action: PayloadAction<Nullable<FaqQuestionsPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setFaqQuestionId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedFaqQuestionId = action.payload;
    },
    closeModals: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentModal, closeModals, setFaqQuestionId, setDetailsButtonAnchorId } =
  faqQuestionsPageModalsSlice.actions;

export const { reducer: faqQuestionsPageModalsReducer } = faqQuestionsPageModalsSlice;
