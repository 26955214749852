import enMessages from "./locales/en.json";
import esMessages from "./locales/es.json";
import frMessages from "./locales/fr.json";
import trMessages from "./locales/tr.json";
import { Language } from "./types";

import type { i18n as i18nApi, Resource } from "i18next";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const defaultNS = "translation";
const defaultLanguage = Language.EN;

const resources: Resource = {
  fr: { [defaultNS]: frMessages },
  en: { [defaultNS]: enMessages },
  es: { [defaultNS]: esMessages },
  tr: { [defaultNS]: trMessages },
};

const i18nInitializer = (): i18nApi => {
  i18next
    .use(initReactI18next)
    .init({
      lng: defaultLanguage,
      defaultNS,
      resources,
      load: "languageOnly",
      saveMissing: true,
      returnEmptyString: false,
      missingKeyNoValueFallbackToKey: false,
      react: {
        useSuspense: false,
      },
      interpolation: {
        escapeValue: false,
      },
    })
    .then(undefined)
    .catch(undefined);

  return i18next;
};
const i18n = i18nInitializer();

export const changeLanguage = (lng: Language): void => {
  i18n.changeLanguage(lng).then(undefined).catch(undefined);
};

export default i18n;
