import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";

import type { GuestStaysFiltersStateStored, GuestStaysFiltersStored } from "./types";

export const useSelectGuestStaysFilters = (): GuestStaysFiltersStateStored => {
  return useAppSelector((state: RootState) => state.guestStaysFilters);
};

export const getNumberOfAppliedFilters = (filters: GuestStaysFiltersStored): number => {
  return Number(filters.minDate !== null) + Number(filters.maxDate !== null);
};

export const useGetNumberOfAppliedGuestStaysFilters = (): number => {
  return useAppSelector((state: RootState) => state.guestsFilters.numberOfAppliedFilters);
};
