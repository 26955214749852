/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { EmployeesPageModalsEnum, EmployeesPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: EmployeesPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedEmployeeId: null,
};

const employeesPageModalsSlice = createSlice({
  name: "employees-page-modals",
  initialState,
  reducers: {
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<EmployeesPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setEmployeeId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedEmployeeId = action.payload;
    },
    closeModals: () => {
      return { ...initialState };
    },
  },
});

export const { setDetailsButtonAnchorId, setCurrentModal, closeModals, setEmployeeId } =
  employeesPageModalsSlice.actions;

export const { reducer: employeesPageModalsReducer } = employeesPageModalsSlice;
