/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { GuestStaysFiltersStateStored, GuestStaysFiltersStored } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: GuestStaysFiltersStored = {
  minDate: null,
  maxDate: null,
};

const initialState: GuestStaysFiltersStateStored = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const guestStaysFiltersSlice = createSlice({
  name: "guet-stays-filters",
  initialState,

  reducers: {
    setMinDate: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.minDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setMaxDate: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.maxDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const { setMinDate, setMaxDate, applyFilters, resetFilters } = guestStaysFiltersSlice.actions;

export const { reducer: guestStaysFiltersReducer } = guestStaysFiltersSlice;
