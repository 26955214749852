/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { StaysPageModalsEnum, StaysPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: StaysPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedStayId: null,
};

const stayPageModalsSlice = createSlice({
  name: "staysPageModals",
  initialState,
  reducers: {
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<StaysPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setStayId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedStayId = action.payload;
    },
    resetStaysPageModalsState: () => {
      return initialState;
    },
  },
});

export const { setDetailsButtonAnchorId, setCurrentModal, setStayId, resetStaysPageModalsState } =
  stayPageModalsSlice.actions;

export const { reducer: staysPageModalsReducer } = stayPageModalsSlice;
