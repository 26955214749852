import { fromDateIsoString } from "~utils/date-utils";

import type { TasksFilters, TasksFiltersState, TasksFiltersStateStored } from "./types";

export const mapDateRangeFromStringToDate = (range: Array<string | null>): Array<Date | null> => {
  const newRange = range.map((date) => (date === null ? null : new Date(date)));
  return newRange;
};

export const mapTasksFiltersStoredToState = (state: TasksFiltersStateStored): TasksFiltersState => {
  const { filters, appliedFilters } = state;
  const newFilters: TasksFilters = {
    employee: filters.employee,
    status: filters.status,
    type: filters.type,
    createdBy: filters.createdBy,
    completeAtMax: fromDateIsoString(filters.completeAtMax),
    completeAtMin: fromDateIsoString(filters.completeAtMin),
  };

  const newAppliedFilters: TasksFilters = {
    employee: appliedFilters.employee,
    status: appliedFilters.status,
    type: appliedFilters.type,
    createdBy: filters.createdBy,
    completeAtMax: fromDateIsoString(appliedFilters.completeAtMax),
    completeAtMin: fromDateIsoString(appliedFilters.completeAtMin),
  };

  return {
    ...state,
    filters: newFilters,
    appliedFilters: newAppliedFilters,
  };
};
