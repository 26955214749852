/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { NotificationChannelsPageModalsEnum, NotificationChannelsPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: NotificationChannelsPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedNotificationChannelId: null,
};

const notificationChannelsPageModalsSlice = createSlice({
  name: "NotificationChannels-page-modals",
  initialState,
  reducers: {
    setCurrentModal: (state, action: PayloadAction<Nullable<NotificationChannelsPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setNotificationChannelId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedNotificationChannelId = action.payload;
    },
    closeModals: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentModal, closeModals, setNotificationChannelId, setDetailsButtonAnchorId } =
  notificationChannelsPageModalsSlice.actions;

export const { reducer: notificationChannelsPageModalsReducer } = notificationChannelsPageModalsSlice;
