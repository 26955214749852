import { useEffect } from "react";

import type { ESortDirections, ETaskTableSortOptions } from "~api/tasks/types";
import { useAppDispatch } from "~store/hooks";
import { useSelectTasksFilters } from "~store/tasks-filters/selectors";
import type { Undefined } from "~utils/types";

import { useTasksSearchParamsSelector } from "./selectors";
import {
  resetPageNumber,
  resetParams,
  setKeyword,
  setPageLimit,
  setPageNumber,
  setSortOption,
  setSortOrder,
} from "./slice";
import type { IUpdateTasksPageSearchParams, TaskTableSearchParams } from "./types";

export const useSetTasksPageSearchParams = (): IUpdateTasksPageSearchParams => {
  const dispatch = useAppDispatch();

  const updatePageNumber = (page: number) => {
    dispatch(setPageNumber(page));
  };

  const updatePageLimit = (limit: number) => {
    dispatch(setPageLimit(limit));
  };

  const updateKeyword = (keyword: string) => {
    dispatch(setKeyword(keyword));
  };

  const updateSortOption = (option: ETaskTableSortOptions) => {
    dispatch(setSortOption(option));
  };

  const updateSortOrder = (order: ESortDirections) => {
    dispatch(setSortOrder(order));
  };
  const resetPageNumberFn = () => {
    dispatch(resetPageNumber());
  };
  const resetParamsFn = () => {
    dispatch(resetParams());
  };

  return {
    updatePageNumber,
    updatePageLimit,
    updateKeyword,
    updateSortOption,
    updateSortOrder,
    resetPageNumber: resetPageNumberFn,
    resetParams: resetParamsFn,
  };
};

export const useGetTasksPageSearchParams = (): { data: Readonly<Undefined<TaskTableSearchParams>> } => {
  const data = useTasksSearchParamsSelector();
  const { resetPageNumber: resetPageNumberToInitial } = useSetTasksPageSearchParams();
  const { appliedFilters } = useSelectTasksFilters();

  useEffect(() => {
    resetPageNumberToInitial();
  }, [appliedFilters, data?.keyword]);

  return { data };
};
