/* eslint-disable no-param-reassign */

import type { OpenSnackBarPayload, SnackBarData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type SnackBarState = {
  value: SnackBarData;
};

const initialState: SnackBarState = {
  value: {
    isOpen: false,
    message: "",
    autoHideDuration: 3000,
    severity: "info",
  },
};

const snackBarSlice = createSlice({
  name: "snackBarState",
  initialState,

  reducers: {
    showSnackBar: (state, action: PayloadAction<OpenSnackBarPayload>) => {
      const { message, severity, autoHideDuration } = action.payload;
      state.value = {
        isOpen: true,
        autoHideDuration: autoHideDuration ?? initialState.value.autoHideDuration,
        message,
        severity,
      };
    },
    closeSnackBar: (state) => {
      state.value.isOpen = false;
    },
  },
});

export const { showSnackBar, closeSnackBar } = snackBarSlice.actions;

export const { reducer: snackBarReducer } = snackBarSlice;
