import { fromDateIsoString } from "~utils/date-utils";

import type { GuestsFilters, GuestsFiltersState, GuestsFiltersStateStored } from "./types";

export const mapDateRangeFromStringToDate = (range: Array<string | null>): Array<Date | null> => {
  const newRange = range.map((date) => (date === null ? null : new Date(date)));
  return newRange;
};

export const mapGuestsFiltersStoredToState = (state: GuestsFiltersStateStored): GuestsFiltersState => {
  const { filters, appliedFilters } = state;
  const newFilters: GuestsFilters = {
    ...filters,
    stayStartsAt: fromDateIsoString(filters.stayStartsAt),
    stayEndsAt: fromDateIsoString(filters.stayEndsAt),
  };

  const newAppliedFilters: GuestsFilters = {
    ...appliedFilters,
    stayStartsAt: fromDateIsoString(appliedFilters.stayStartsAt),
    stayEndsAt: fromDateIsoString(appliedFilters.stayEndsAt),
  };

  return {
    ...state,
    filters: newFilters,
    appliedFilters: newAppliedFilters,
  };
};
