import { useAppDispatch } from "~store/hooks";

import { closeSnackBar, showSnackBar } from "./slice";
import type { UseCloseSnackBarReturnType, UseOpenSnackBarReturnType } from "./types";

export const useOpenSnackBar = (): UseOpenSnackBarReturnType => {
  const dispatch = useAppDispatch();
  return {
    openSnackBar: (payload) => {
      dispatch(showSnackBar(payload));
    },
  };
};

export const useCloseSnackBar = (): UseCloseSnackBarReturnType => {
  const dispatch = useAppDispatch();
  return {
    closeSnackBar: () => {
      dispatch(closeSnackBar());
    },
  };
};
