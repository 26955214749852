import type { UsernameTypeEnum } from "./types/global-types";
import type { AcceptedLanguages } from "./constants";
import type { Nullable } from "./types";

const PreferredLanguageKey = "preferred_language";
const LoginCredentialKey = "login_credential";
const LoginCredentialTypeKey = "login_credential_type";

export const storeItem = (key: string, value: string): void => {
  if (typeof window === "undefined") return;

  localStorage.setItem(key, value);
};

export const getItem = (key: string): Nullable<string> => {
  if (typeof window === "undefined") return null;

  return localStorage.getItem(key);
};

export const storePreferredLanguage = (language: AcceptedLanguages): void => {
  storeItem(PreferredLanguageKey, language);
};

export const getPreferredLanguage = (): Nullable<string> => {
  return getItem(PreferredLanguageKey);
};

export const storeLoginCredential = (value: string): void => {
  storeItem(LoginCredentialKey, value);
};
export const storeLoginCredentialType = (value: UsernameTypeEnum): void => {
  storeItem(LoginCredentialTypeKey, value);
};

export const getLoginCredential = (): Nullable<string> => {
  return getItem(LoginCredentialKey);
};

export const getLoginCredentialType = (): Nullable<UsernameTypeEnum> => {
  return getItem(LoginCredentialTypeKey) as UsernameTypeEnum;
};
