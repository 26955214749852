import type { Pageable, Task, TaskStatus } from "~utils/types/global-types";

import type { Consumer } from "./../../utils/types";

import type { UseQueryResult } from "react-query";

export enum ETaskTableSortOptions {
  TITLE = "title",
  TYPE = "type",
  CUSTOMER_NAME = "CUSTOMER_NAME",
  ASSIGNED_TO = "assignedTo",
  CURRENT_STATUS = "currentStatus",
  CREATED_AT = "createdAt",
  COMPLETED_AT = "completeAt",
  PROCESSED_AT = "processedAt",
  CREATED_BY = "createdBy",
  PROCESSED_By = "processedBy",
  DEFAULT = "",
}
export enum ESortDirections {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TaskScopeEnum {
  EMPLOYEE_ONLY = "EMPLOYEE_ONLY",
  TEAM_ONLY = "TEAM_ONLY",
  ALL = "ALL",
}

export interface RequestParams {
  page: number;
  limit: number;
  keyword: string;
  currentStatus: TaskStatus[];
  assignedTo: string;
  type: string;
  completeAtMin: string;
  completeAtMax: string;
  sort: ETaskTableSortOptions;
  order: ESortDirections;
  actionId: string;
  scope: TaskScopeEnum;
  createdBy: string;
}

export type GetTasksResponse = Pageable<Task>;
export type UseGetTasksType = (params: Partial<RequestParams>, isEnabled?: boolean) => UseQueryResult<GetTasksResponse>;

export type UseGetTaskType = (
  id: string,
  isEnabled?: boolean,
  onSuccessFn?: Consumer<Task>,
  onErrorFn?: Consumer<unknown>,
) => UseQueryResult<Task>;
