/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { TeamsPageModalsEnum, TeamsPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: TeamsPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedTeamId: null,
};

const teamsPageModalsSlice = createSlice({
  name: "teams-page-modals",
  initialState,
  reducers: {
    setCurrentModal: (state, action: PayloadAction<Nullable<TeamsPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setTeamId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedTeamId = action.payload;
    },

    closeModals: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentModal, closeModals, setTeamId, setDetailsButtonAnchorId } = teamsPageModalsSlice.actions;

export const { reducer: teamsPageModalsReducer } = teamsPageModalsSlice;
