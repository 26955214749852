/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";
import type {
  NotificationPlatformEnum,
  NotificationReasonEnum,
  NotificationReceiverCategoryEnum,
} from "~utils/types/global-types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { NotificationsHistoryFiltersStateStored, NotificationsHistoryFiltersStored } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: NotificationsHistoryFiltersStored = {
  platform: null,
  reason: null,
  receiverCategory: null,
  startDate: null,
  endDate: null,
};

const initialState: NotificationsHistoryFiltersStateStored = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const notificationsHistoryFiltersSlice = createSlice({
  name: "notifications-history-filters",
  initialState,

  reducers: {
    setPlatform: (state, action: PayloadAction<Nullable<NotificationPlatformEnum>>) => {
      const draft = state;
      draft.filters.platform = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setReason: (state, action: PayloadAction<NotificationReasonEnum>) => {
      const draft = state;
      draft.filters.reason = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setReceiverCategory: (state, action: PayloadAction<NotificationReceiverCategoryEnum>) => {
      const draft = state;
      draft.filters.receiverCategory = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setStartDate: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.startDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setEndDate: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.endDate = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const { setPlatform, setReason, setReceiverCategory, setStartDate, setEndDate, applyFilters, resetFilters } =
  notificationsHistoryFiltersSlice.actions;

export const { reducer: notificationsHistoryFiltersReducer } = notificationsHistoryFiltersSlice;
