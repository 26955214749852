/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { GuestNeedsTableDataStored } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type GuestNeedsTableDataState = {
  pageData: Undefined<GuestNeedsTableDataStored>;
};

const initialState: GuestNeedsTableDataState = {
  pageData: undefined,
};

const guestNeedsSlice = createSlice({
  name: "guest-needs",
  initialState,

  reducers: {
    setGuestNeedsTableData: (state, action: PayloadAction<GuestNeedsTableDataStored>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
    resetGuestNeedsTableData: (state) => {
      const draft = state;
      draft.pageData = undefined;
    },
  },
});

export const { setGuestNeedsTableData, resetGuestNeedsTableData } = guestNeedsSlice.actions;

export const { reducer: guestNeedsReducer } = guestNeedsSlice;
