import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";

import type { GuestNeedsFiltersStateStored, GuestNeedsFiltersStored } from "./types";

export const useSelectGuestNeedsFilters = (): GuestNeedsFiltersStateStored => {
  return useAppSelector((state: RootState) => state.guestNeedsFilters);
};

export const getNumberOfAppliedFilters = (filters: GuestNeedsFiltersStored): number => {
  return (
    Number(Object.values(filters.type).includes(true)) +
    Number(filters.status !== null) +
    Number(filters.minDate !== null) +
    Number(filters.maxDate !== null)
  );
};

export const useGetNumberOfAppliedGuestNeedsFilters = (): number => {
  return useAppSelector((state: RootState) => state.guestsFilters.numberOfAppliedFilters);
};
