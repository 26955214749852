/* eslint-disable no-param-reassign */

import type { GuestStaysTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type GuestStaysTableSearchParamsState = {
  params: GuestStaysTableSearchParams;
};
const initialState: GuestStaysTableSearchParamsState = {
  params: {
    page: 1,
    limit: 10,
    keyword: null,
  },
};

const guestStaysSearchParamsSlice = createSlice({
  name: "guest-stays-search-params",
  initialState,

  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.params.keyword = action.payload;
    },
    resetSearchParams: () => {
      return initialState;
    },
    resetPageNumber: (state) => {
      const draft = state;
      draft.params.page = initialState.params.page;
    },
  },
});

export const { setPageNumber, setPageLimit, setKeyword, resetSearchParams, resetPageNumber } =
  guestStaysSearchParamsSlice.actions;

export const { reducer: guestStaysSearchParamsReducer } = guestStaysSearchParamsSlice;
