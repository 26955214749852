/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { TeamOperationsFilters, TeamOperationsFiltersState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: TeamOperationsFilters = { team: null, employee: null, isAutoClose: null, isAutoQualify: null };

const initialState: TeamOperationsFiltersState = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const teamOperationsFiltersSlice = createSlice({
  name: "team-operations-filters",
  initialState,

  reducers: {
    setTeamFilter: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.filters.team = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setEmployeeFilter: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.filters.employee = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setIsAutoQualifyFilter: (state, action: PayloadAction<Nullable<boolean>>) => {
      const draft = state;
      draft.filters.isAutoQualify = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setIsAutoCloseFilter: (state, action: PayloadAction<Nullable<boolean>>) => {
      const draft = state;
      draft.filters.isAutoClose = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const {
  setTeamFilter,
  setEmployeeFilter,
  setIsAutoQualifyFilter,
  setIsAutoCloseFilter,
  applyFilters,
  resetFilters,
} = teamOperationsFiltersSlice.actions;

export const { reducer: teamOperationsFiltersReducer } = teamOperationsFiltersSlice;
