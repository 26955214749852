/* eslint-disable no-param-reassign */

import { ESortDirections, ETaskTableSortOptions } from "~api/tasks/types";

import type { TaskTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TaskTableSearchParamsState = {
  params: TaskTableSearchParams;
};

const initialState: TaskTableSearchParamsState = {
  params: {
    page: 1,
    limit: 10,
    keyword: "",
    sort: ETaskTableSortOptions.DEFAULT,
    order: ESortDirections.ASC,
    actionId: "",
  },
};
const guestNeedTasksSearchParamsSlice = createSlice({
  name: "guest-need-tasks-search-params",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.params.keyword = action.payload;
    },
    setSortOption: (state, action: PayloadAction<ETaskTableSortOptions>) => {
      const draft = state;
      draft.params.sort = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<ESortDirections>) => {
      const draft = state;
      draft.params.order = action.payload;
    },
    setActionId: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.params.actionId = action.payload;
    },
    resetPageNumber: (state) => {
      const draft = state;
      draft.params.page = initialState.params.page;
    },
  },
});

export const { setPageNumber, setKeyword, setSortOrder, setPageLimit, setSortOption, setActionId, resetPageNumber } =
  guestNeedTasksSearchParamsSlice.actions;

export const { reducer: guestNeedTasksSearchParamsReducer } = guestNeedTasksSearchParamsSlice;
