import type { OperationDetails } from "~utils/types/global-types";

import type { OperationDetailsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: OperationDetailsState = {
  operation: null,
};

const operationDetailsSlice = createSlice({
  name: "operation-details-modals",
  initialState,

  reducers: {
    setOperation: (state, action: PayloadAction<OperationDetails>) => {
      const draft = state;
      draft.operation = action.payload;
    },
  },
});

export const { setOperation } = operationDetailsSlice.actions;

export const { reducer: operationDetailsReducer } = operationDetailsSlice;
