import type FrJson from "./locales/fr.json";

export enum Language {
  FR = "fr",
  EN = "en",
  ES = "es",
  TR = "tr",
}

type DefaultLocale = typeof FrJson;

export type RecursiveKeyOf<TObj extends Record<string, unknown>> = {
  [TKey in keyof TObj & (number | string)]: TObj[TKey] extends unknown[]
    ? `${TKey}`
    : TObj[TKey] extends Record<string, unknown>
    ? `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`;
}[keyof TObj & (number | string)];

export type TxKeyPath = RecursiveKeyOf<DefaultLocale>;
