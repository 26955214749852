import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";
import { isDefined } from "~utils/boolean-utils";

import type { ServicesFilters, ServicesFiltersState } from "./types";

export const useSelectServicesFilters = (): ServicesFiltersState => {
  return useAppSelector((state: RootState) => state.servicesFilters);
};

export const getNumberOfAppliedFilters = (filters: ServicesFilters): number => {
  return Number(isDefined(filters.category)) + Number(isDefined(filters.type)) + Number(isDefined(filters.isArchived));
};

export const useGetNumberOfAppliedServicesFilters = (): number => {
  return useAppSelector((state: RootState) => state.servicesFilters.numberOfAppliedFilters);
};
