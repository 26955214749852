import type { Callback } from "./types";

export const voidFunction: Callback = () => undefined;

export function stringifyRecord(record: Record<string, boolean>): string | null {
  const values: string[] = [];
  for (const key of Object.keys(record)) {
    const value = record[key] ?? false;
    if (value) values.push(key);
  }
  const stringified = values.join(",");
  return stringified === "" ? null : stringified;
}

export function extractTrueKeysFromRecord(record: Record<string, boolean>): string[] {
  const values: string[] = [];
  for (const key of Object.keys(record)) {
    const value = record[key] ?? false;
    if (value) values.push(key);
  }
  return values;
}

export function stringifyArray(array: string[]): string {
  return array.join(",");
}
