/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { GuestStaysTableData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type GuestStaysTableDataState = {
  pageData: Undefined<GuestStaysTableData>;
};

const initialState: GuestStaysTableDataState = {
  pageData: undefined,
};

const guestStaysSlice = createSlice({
  name: "guest-stays",
  initialState,

  reducers: {
    setGuestStaysTableData: (state, action: PayloadAction<GuestStaysTableData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
    resetGuestStaysTableData: () => {
      return initialState;
    },
  },
});

export const { setGuestStaysTableData, resetGuestStaysTableData } = guestStaysSlice.actions;

export const { reducer: guestStaysReducer } = guestStaysSlice;
