/* eslint-disable no-param-reassign */

import { isDefined } from "~utils/boolean-utils";
import type { Hotel } from "~utils/types/global-types";

import type { CurrentUser, CurrentUserState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CurrentUserState = {
  value: null,
};

const currentUserSlice = createSlice({
  name: "current-user",
  initialState,

  reducers: {
    setCurrentUser: (state, action: PayloadAction<CurrentUser>) => {
      state.value = action.payload;
    },
    setUserHotel: (state, action: PayloadAction<Hotel>) => {
      const draft = state.value;
      if (isDefined(draft)) {
        draft.hotel = {
          ...action.payload,
        };
      }
    },
    removeCurrentUser: (state) => {
      state.value = null;
    },
  },
});

export const { setCurrentUser, removeCurrentUser, setUserHotel } = currentUserSlice.actions;

export const { reducer: currentUserReducer } = currentUserSlice;
