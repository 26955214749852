import { useAppDispatch } from "~store/hooks";
import { toDateIsoString } from "~utils/date-utils";
import type { Nullable } from "~utils/types";

import { mapTasksFiltersStoredToState } from "./mappers";
import { useSelectTasksFilters } from "./selectors";
import {
  applyFilters,
  resetFilters,
  setCompleteAtMax,
  setCompleteAtMin,
  setCreatedByFilter,
  setEmployeeFilter,
  setStatusFilter,
  setTypeFilter,
} from "./slice";
import type {
  CreatedByFilter,
  EmployeeFilter,
  StatusFilter,
  TasksFiltersState,
  TypesFilter,
  UseTasksFiltersReturnType,
} from "./types";

export const useTasksFilters = (): UseTasksFiltersReturnType => {
  const dispatch = useAppDispatch();

  const setTypeFilterFn = (type: TypesFilter) => {
    dispatch(setTypeFilter(type));
  };

  const setStatusFilterFn = (status: StatusFilter) => {
    dispatch(setStatusFilter(status));
  };
  const setStatusFiltersFn = (statusList: StatusFilter[]) => {
    statusList.forEach((status) => {
      dispatch(setStatusFilter(status));
    });
  };

  const setEmployeeFilterFn = (employee: EmployeeFilter) => {
    dispatch(setEmployeeFilter(employee));
  };

  const setCreatedByFilterFn = (createdBy: CreatedByFilter) => {
    dispatch(setCreatedByFilter(createdBy));
  };

  const setCompleteAtMaxFn = (date: Nullable<Date>) => {
    dispatch(setCompleteAtMax(toDateIsoString(date)));
  };

  const setCompleteAtMinFn = (date: Nullable<Date>) => {
    dispatch(setCompleteAtMin(toDateIsoString(date)));
  };

  const applyFiltersFn = () => {
    dispatch(applyFilters());
  };

  const resetFiltersFn = () => {
    dispatch(resetFilters());
  };

  return {
    setTypeFilter: setTypeFilterFn,
    setStatusFilter: setStatusFilterFn,
    setStatusFilters: setStatusFiltersFn,
    setEmployeeFilter: setEmployeeFilterFn,
    setCreatedByFilter: setCreatedByFilterFn,
    applyFilters: applyFiltersFn,
    resetFilters: resetFiltersFn,
    setCompleteAtMax: setCompleteAtMaxFn,
    setCompleteAtMin: setCompleteAtMinFn,
  };
};

export const useGetTasksFilters = (): TasksFiltersState => {
  const tasksFilters = useSelectTasksFilters();
  return mapTasksFiltersStoredToState(tasksFilters);
};
