/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { GuestConversationsPageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type GuestConversationsPageDataState = {
  pageData: Undefined<GuestConversationsPageData>;
};

const initialState: GuestConversationsPageDataState = {
  pageData: undefined,
};

const guestConversationsSlice = createSlice({
  name: "guest-conversations",
  initialState,

  reducers: {
    setGuestConversationsPageData: (state, action: PayloadAction<GuestConversationsPageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setGuestConversationsPageData } = guestConversationsSlice.actions;

export const { reducer: guestConversationsReducer } = guestConversationsSlice;
