/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { TeamOperationsPageModalsEnum, TeamOperationsPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: TeamOperationsPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedTeamOperationId: null,
};

const teamOperationPageModalsSlice = createSlice({
  name: "team-operations-page-modals",
  initialState,
  reducers: {
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<TeamOperationsPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setTeamOperationId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedTeamOperationId = action.payload;
    },
    resetTeamOperationsPageModalsState: (state) => {
      const draft = state;
      draft.currentModal = null;
      draft.detailsButtonAnchorId = null;
      draft.selectedTeamOperationId = null;
    },
  },
});

export const { setDetailsButtonAnchorId, setCurrentModal, setTeamOperationId, resetTeamOperationsPageModalsState } =
  teamOperationPageModalsSlice.actions;

export const { reducer: teamOperationsPageModalsReducer } = teamOperationPageModalsSlice;
