/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import { type NotificationChannelsTablePageData, NotificationsPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type NotificationChannelsTableDataState = {
  pageData: Undefined<NotificationChannelsTablePageData>;
  currentTab: NotificationsPageTabsEnum;
};

const initialState: NotificationChannelsTableDataState = {
  pageData: undefined,
  currentTab: NotificationsPageTabsEnum.TEAM_CHANNELS,
};

const notificationChannelsSlice = createSlice({
  name: "notification-channels",
  initialState,

  reducers: {
    setNotificationChannelsTableData: (state, action: PayloadAction<NotificationChannelsTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<NotificationsPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
  },
});

export const { setNotificationChannelsTableData, setCurrentTab } = notificationChannelsSlice.actions;

export const { reducer: notificationChannelsReducer } = notificationChannelsSlice;
