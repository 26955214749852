import type { Guest } from "~utils/types/global-types";

import type { GuestDetailsModalsEnum, GuestDetailsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: GuestDetailsState = {
  currentModal: null,
  guest: null,
};

const guestDetailsSlice = createSlice({
  name: "guet-details-modals",
  initialState,

  reducers: {
    setCurrentModal: (state, action: PayloadAction<GuestDetailsModalsEnum>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setGuest: (state, action: PayloadAction<Guest>) => {
      const draft = state;
      draft.guest = action.payload;
    },
    closeModals: (state) => {
      const draft = state;
      draft.currentModal = null;
    },
  },
});

export const { setCurrentModal, setGuest, closeModals } = guestDetailsSlice.actions;

export const { reducer: guestDetailsReducer } = guestDetailsSlice;
