/* eslint-disable no-param-reassign */

import type { OperationScopeEnum } from "~api/operations/types";
import type { Nullable } from "~utils/types";
import type { OperationStatus } from "~utils/types/global-types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { OperationsFilters, OperationsFiltersState, TypesFilter } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: OperationsFilters = {
  actionType: {
    BOOKING: false,
    INCIDENT: false,
    ORDER: false,
    REQUEST: false,
  },
  status: {
    CANCELED: false,
    CLOSE: false,
    DO: false,
    DONE: false,
    IN_PROGRESS: false,
    QUALIFY: false,
  },
  scope: null,
};

const initialState: OperationsFiltersState = {
  filters: initialFilters,
  numberOfAppliedFilters: 0,
  appliedFilters: initialFilters,
};

const operationsFiltersSlice = createSlice({
  name: "operations-filters",
  initialState,

  reducers: {
    setActionTypeFilter: (state, action: PayloadAction<TypesFilter>) => {
      const draft = state;
      const previousValue = draft.filters.actionType[action.payload];
      draft.filters.actionType[action.payload] = !previousValue;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setStatusFilter: (state, action: PayloadAction<OperationStatus>) => {
      const draft = state;
      const previousValue = draft.filters.status[action.payload];
      draft.filters.status[action.payload] = !previousValue;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setScopeFilter: (state, action: PayloadAction<Nullable<OperationScopeEnum>>) => {
      const draft = state;
      draft.filters.scope = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const { setActionTypeFilter, setStatusFilter, setScopeFilter, applyFilters, resetFilters } =
  operationsFiltersSlice.actions;

export const { reducer: operationsFiltersReducer } = operationsFiltersSlice;
