/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { GuestConversationsPageModalsEnum, GuestConversationsPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: GuestConversationsPageModalsState = {
  currentModal: null,
  conversationId: null,
};

const guestConversationsPageModalsSlice = createSlice({
  name: "guests-conversations-page-modals",
  initialState,
  reducers: {
    setCurrentModal: (state, action: PayloadAction<Nullable<GuestConversationsPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setConversationId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.conversationId = action.payload;
    },
    resetGuestConversationsPageModalsState: (state) => {
      const draft = state;
      draft.currentModal = null;
      draft.conversationId = null;
    },
  },
});

export const { setCurrentModal, setConversationId, resetGuestConversationsPageModalsState } =
  guestConversationsPageModalsSlice.actions;

export const { reducer: guestConversationsPageModalsReducer } = guestConversationsPageModalsSlice;
