import type { TasksPageState } from "./types";
import { TasksPageTabsEnum } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: TasksPageState = {
  currentTab: TasksPageTabsEnum.TODO_TASKS,
};

const tasksPageSlice = createSlice({
  name: "tasks-page",
  initialState,

  reducers: {
    setCurrentTab: (state, action: PayloadAction<TasksPageTabsEnum>) => {
      const draft = state;
      draft.currentTab = action.payload;
    },
  },
});

export const { setCurrentTab } = tasksPageSlice.actions;

export const { reducer: tasksPageReducer } = tasksPageSlice;
