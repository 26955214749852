import "dayjs/locale/fr";

import { isDefined, isNullOrUndefined } from "./boolean-utils";

import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export const FORMAT_FOR_MONTH = "MMMM YYYY";
export const FORMAT_FOR_DAY_MONTH = "DD MMMM";

export const setAppTimezone = (zone: string): void => {
  dayjs.tz.setDefault(zone);
};

export const toDateIsoString = (dateString: Date | string | null): string | null => {
  return isDefined(dateString) ? dayjs(dateString).toISOString() : null;
};

export const fromDateIsoString = (dateString: string | null): Date | null => {
  return isDefined(dateString) ? new Date(dateString) : null;
};

export const formatDate = (date: Date, format: string, userTimezone: string | null = null): string => {
  if (isNullOrUndefined(userTimezone)) {
    const localDate = utcToDefaultTimezone(date);

    return dayjs(localDate).format(format);
  }
  const timezoneDaysjs = utcToTimezone(date, userTimezone);
  return timezoneDaysjs.format(format);
};

export function utcToLocalDate(date: Date): Date {
  return dayjs.utc(date).local().toDate();
}

export function utcToDefaultTimezone(date: Date): Dayjs {
  return dayjs.utc(date).tz();
}

export function utcToTimezone(date: Date, userTimezone: string | null): Dayjs {
  if (isNullOrUndefined(userTimezone)) {
    return utcToDayjs(date);
  }
  return dayjs.utc(date).tz(userTimezone);
}

export function timezoneToUtc(date: Date, randomTimezone: string): Dayjs {
  return dayjs.tz(date, randomTimezone).utc();
}

function convertMsToTime(milliseconds: number) {
  const seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  minutes %= 60;

  return `${padTo2Digits(hours)} hours ${padTo2Digits(minutes)} minutes`;
}

export function getRemainingTime(dueDate: Date | null): string {
  if (dueDate === null) return "N/A";
  const parsedDueDate = utcToLocalDate(dueDate);

  const msBetweenDates = parsedDueDate.getTime() - Date.now();

  return convertMsToTime(Math.max(msBetweenDates, 0));
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function slaToDate(minutes: number | null): Date | null {
  if (minutes === null) return null;
  const milliseconds = minutes * 60 * 1000;
  return new Date(Date.now() + milliseconds);
}

export function utcToDayjs(date: Date, userTimezone?: string | null): Dayjs {
  if (isNullOrUndefined(userTimezone)) {
    return dayjs.utc(date).local();
  }

  return dayjs.utc(date).tz(userTimezone);
}

export function isOutDated(date: Date): boolean {
  return dayjs.utc(date).local().isBefore(new Date());
}

export function extractSlaFromMinutes(minutes: number): string {
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;

  // Rounding the remaining minutes to the nearest multiple of 5
  remainingMinutes = Math.round(remainingMinutes / 5) * 5;

  if (remainingMinutes === 60) {
    hours++;
    remainingMinutes = 0;
  }

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours}h ${remainingMinutes}min`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${remainingMinutes}min`;
}
