import type { Nullable } from "~utils/types";

import type { CreateEmployeePageModalsEnum, CreateEmployeePageState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: CreateEmployeePageState = {
  currentModal: null,
  teamId: null,
};

const createEmployeePageSlice = createSlice({
  name: "create-employee-page",
  initialState,

  reducers: {
    setCurrentModal: (state, action: PayloadAction<CreateEmployeePageModalsEnum>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setTeamId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.teamId = action.payload;
    },
    closeModals: (state) => {
      const draft = state;
      draft.currentModal = null;
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});

export const { setCurrentModal, setTeamId, closeModals, resetState } = createEmployeePageSlice.actions;

export const { reducer: createEmployeePageReducer } = createEmployeePageSlice;
