/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";
import type { NotificationPlatformEnum } from "~utils/types/global-types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { NotificationChannelsFilters, NotificationChannelsFiltersState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: NotificationChannelsFilters = {
  team: null,
  isActive: null,
  platform: null,
};

const initialState: NotificationChannelsFiltersState = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const notificationChannelsFiltersSlice = createSlice({
  name: "notification-channels-filters",
  initialState,

  reducers: {
    setTeamFilter: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.filters.team = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setPlatformFilter: (state, action: PayloadAction<Nullable<NotificationPlatformEnum>>) => {
      const draft = state;
      draft.filters.platform = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setIsActiveFilter: (state, action: PayloadAction<Nullable<boolean>>) => {
      const draft = state;
      draft.filters.isActive = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const { setTeamFilter, setPlatformFilter, setIsActiveFilter, applyFilters, resetFilters } =
  notificationChannelsFiltersSlice.actions;

export const { reducer: notificationChannelsFiltersReducer } = notificationChannelsFiltersSlice;
