/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { ServicesPageModalsEnum, ServicesPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ServicesPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedServiceId: null,
};

const servicesPageModalsSlice = createSlice({
  name: "services-page-modals",
  initialState,
  reducers: {
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<ServicesPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setServiceId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedServiceId = action.payload;
    },
    closeModals: () => {
      return { ...initialState };
    },
  },
});

export const { setDetailsButtonAnchorId, setCurrentModal, closeModals, setServiceId } = servicesPageModalsSlice.actions;

export const { reducer: servicesPageModalsReducer } = servicesPageModalsSlice;
