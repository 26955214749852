/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { EmployeesTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type EmployeesTableDataState = {
  pageData: Undefined<EmployeesTablePageData>;
};

const initialState: EmployeesTableDataState = {
  pageData: undefined,
};

const EmployeesSlice = createSlice({
  name: "employees",
  initialState,

  reducers: {
    setEmployeesTableData: (state, action: PayloadAction<EmployeesTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setEmployeesTableData } = EmployeesSlice.actions;

export const { reducer: EmployeesReducer } = EmployeesSlice;
