/* eslint-disable no-param-reassign */

import type { GuestConversationsTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type GuestConversationsTableSearchParamsState = {
  params: GuestConversationsTableSearchParams;
};
const initialState: GuestConversationsTableSearchParamsState = {
  params: {
    page: 1,
    limit: 5,
  },
};

const guestConversationsSearchParamsSlice = createSlice({
  name: "guest-conversations-search-params",
  initialState,

  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
  },
});

export const { setPageNumber, setPageLimit } = guestConversationsSearchParamsSlice.actions;

export const { reducer: guestConversationsSearchParamsReducer } = guestConversationsSearchParamsSlice;
