/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { HotelRoomsTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type HotelRoomsTableDataState = {
  pageData: Undefined<HotelRoomsTablePageData>;
};

const initialState: HotelRoomsTableDataState = {
  pageData: undefined,
};

const hotelRoomsSlice = createSlice({
  name: "hotel-rooms",
  initialState,

  reducers: {
    setHotelRoomsTableData: (state, action: PayloadAction<HotelRoomsTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setHotelRoomsTableData } = hotelRoomsSlice.actions;

export const { reducer: hotelRoomsReducer } = hotelRoomsSlice;
