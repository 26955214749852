/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { HotelRoomsPageModalsEnum, HotelRoomsPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: HotelRoomsPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedHotelRoomId: null,
};

const hotelRoomsPageModalsSlice = createSlice({
  name: "hotel-rooms-page-modals",
  initialState,
  reducers: {
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<HotelRoomsPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setHotelRoomId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedHotelRoomId = action.payload;
    },
    resetHotelRoomsPageModalsState: (state) => {
      const draft = state;
      draft.currentModal = null;
      draft.detailsButtonAnchorId = null;
      draft.selectedHotelRoomId = null;
    },
  },
});

export const { setDetailsButtonAnchorId, setCurrentModal, setHotelRoomId, resetHotelRoomsPageModalsState } =
  hotelRoomsPageModalsSlice.actions;

export const { reducer: hotelRoomsPageModalsReducer } = hotelRoomsPageModalsSlice;
