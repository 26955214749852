import { useAppSelector } from "~store/hooks";
import type { RootState } from "~store/types";
import { isDefined } from "~utils/boolean-utils";

import type { NotificationChannelsFilters, NotificationChannelsFiltersState } from "./types";

export const useSelectNotificationChannelsFilters = (): NotificationChannelsFiltersState => {
  return useAppSelector((state: RootState) => state.notificationChannelsFilters);
};

export const getNumberOfAppliedFilters = (filters: NotificationChannelsFilters): number => {
  return Number(isDefined(filters.team)) + Number(isDefined(filters.isActive)) + Number(isDefined(filters.platform));
};

export const useGetNumberOfAppliedNotificationChannelsFilters = (): number => {
  return useAppSelector((state: RootState) => state.notificationChannelsFilters.numberOfAppliedFilters);
};
