import type { Consumer, IdWithPayload, Nullable } from "~utils/types";
import type {
  ActionType,
  ESortDirections,
  Operation,
  OperationCancellationReasonEnum,
  OperationDetails,
  OperationStatus,
  Pageable,
} from "~utils/types/global-types";

import type { AxiosResponse } from "axios";
import type { UseMutationResult, UseQueryResult } from "react-query";

export interface CommentDTO {
  comment: string;
}
export interface CancelOperationDTO {
  comment: string;
  reason: OperationCancellationReasonEnum;
}

export interface TakeInChargeDTO {
  completeAt: Nullable<string>;
}

export type CloseOperationDTO = CommentDTO;

export type ResetOperationDTO = CommentDTO;

export type BlockOperationDTO = CommentDTO;

export type ProcessOperationDTO = CommentDTO;

export interface QualifyOperationDTO {
  instructions: Nullable<string>;
  assignedToEmployee: Nullable<string>;
  completeAt: Nullable<string>;
}

export enum OperationScopeEnum {
  EMPLOYEE_ONLY = "EMPLOYEE_ONLY",
  TEAM_ONLY = "TEAM_ONLY",
  ALL = "ALL",
}

export enum EOperationTableSortOptions {
  CODE = "code",
  TYPE = "type",
  STATUS = "status",
  CREATED_BY = "createdBy",
  PROCESSED_AT = "processedAt",
  CREATED_AT = "createdAt",
  DEFAULT = "",
}

export interface OperationsFilters {
  page?: number;
  limit?: number;
  keyword?: string;
  sort?: EOperationTableSortOptions;
  order?: ESortDirections;
  actionType?: ActionType[];
  status?: OperationStatus[];
  scope?: OperationScopeEnum;
}

export type UseCancelOperationType = (
  onSuccessFn?: Consumer<AxiosResponse>,
  onErrorFn?: Consumer<unknown>,
) => UseMutationResult<AxiosResponse, unknown, IdWithPayload<CancelOperationDTO>>;

export type UseBlockOperationType = (
  onSuccessFn?: Consumer<AxiosResponse>,
  onErrorFn?: Consumer<unknown>,
) => UseMutationResult<AxiosResponse, unknown, IdWithPayload<BlockOperationDTO>>;

export type UseCloseOperationType = (
  onSuccessFn?: Consumer<AxiosResponse>,
  onErrorFn?: Consumer<unknown>,
) => UseMutationResult<AxiosResponse, unknown, IdWithPayload<CloseOperationDTO>>;

export type UseResetOperationType = (
  onSuccessFn?: Consumer<AxiosResponse>,
  onErrorFn?: Consumer<unknown>,
) => UseMutationResult<AxiosResponse, unknown, IdWithPayload<ResetOperationDTO>>;

export type UseQualifyOperationType = (
  onSuccessFn?: Consumer<AxiosResponse>,
  onErrorFn?: Consumer<unknown>,
) => UseMutationResult<AxiosResponse, unknown, IdWithPayload<QualifyOperationDTO>>;

export type UseProcessOperationType = (
  onSuccessFn?: Consumer<AxiosResponse>,
  onErrorFn?: Consumer<unknown>,
) => UseMutationResult<AxiosResponse, unknown, IdWithPayload<FormData>>;

export type UseTakeInChargeType = (
  onSuccessFn?: Consumer<AxiosResponse>,
  onErrorFn?: Consumer<unknown>,
) => UseMutationResult<AxiosResponse, unknown, IdWithPayload<TakeInChargeDTO>>;

export type UseGetOperationsPageType = (params: {
  filters: OperationsFilters;
  onSuccessFn?: Consumer<Pageable<Operation>>;
  onErrorFn?: Consumer<unknown>;
}) => UseQueryResult<Pageable<Operation>>;

export type UseGetOperationType = (params: {
  id: string;
  isEnabled?: boolean;
  onSuccessFn?: Consumer<OperationDetails>;
  onErrorFn?: Consumer<unknown>;
}) => UseQueryResult<OperationDetails>;
