import axiosInstance from "~utils/axiosInstance";
import type { User } from "~utils/types/global-types";

import type { UpdateUserPasswordDTO } from "./types";

import type { AxiosResponse } from "axios";

export const getConnectedUser = async (): Promise<User> => {
  const { data }: AxiosResponse<User> = await axiosInstance.get(`/me`);
  return data;
};

export const updateUserPassword = async (dto: UpdateUserPasswordDTO): Promise<User> => {
  const { data }: AxiosResponse<User> = await axiosInstance.put("/me/newPassword", dto);
  return data;
};
