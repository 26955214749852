import type { Callback, Consumer, Nullable } from "~utils/types";

export enum ImportGuestSteps {
  LOAD_FILE = "load_file",
  DATA_MAPPING = "data_mapping",
  DATA_MAPPING_RESULTS = "data_mapping_results",
  GUESTS_IMPORT_RESULTS = "guests_import_results",
}

export enum ImportedDataType {
  GUEST_ONLY = "guest",
  GUEST_AND_STAY = "guest_and_stay",
  STAY_ONLY = "stay_only",
}

export enum ImportGuestStatus {
  Success = "success",
  Error = "error",
}

export type OneStepAccordionState = {
  isOpen: boolean;
};

export type StepAccordiosnState = Record<ImportGuestSteps, OneStepAccordionState>;

export type ImportGuestsHeaders = Record<string, string>;
export type ImportGuestsRows = Array<Record<string, string>>;

export type ImportGuestsData = {
  headers: ImportGuestsHeaders;
  rows: ImportGuestsRows;
};

export interface GuestRowData {
  firstName: string;
  lastName: string;
  phone: string;
  preferredLanguage: string;
  stayDateStart: string;
  stayDateEnd: string;
  room?: string;
}

export const OptionalFields: Array<keyof GuestRowData> = ["room"];

export const fieldsToBeMapped: Array<keyof GuestRowData> = [
  "firstName",
  "lastName",
  "phone",
  "preferredLanguage",
  "stayDateStart",
  "stayDateEnd",
  "room",
];

export type ImportGuestMapping = Record<keyof GuestRowData, Nullable<string>>;

export type OverviewResults = {
  totalRows: number;
  acceptedRows: number;
  rejectedRows: number;
};

export type GuestRowResult = {
  index: number;
  status: ImportGuestStatus;
  reason: string;
};

export type ImportGuestState = {
  importedData: Nullable<ImportGuestsData>;
  mapping: ImportGuestMapping;
  steps: StepAccordiosnState;
  rowsResults: GuestRowResult[];
  resultsOverview: OverviewResults;
  guestsImportIsDone: boolean;
  loadedFileName: Nullable<string>;
};

export interface IUseImportGuests {
  reset: Callback;
  setImportedData: Consumer<ImportGuestsData>;
  toggleStepAccordion: Consumer<ImportGuestSteps>;
  openNextStepAccordion: Consumer<ImportGuestSteps>;
  updateMapping: Consumer<ImportGuestMapping>;
  updateResultsOverview: Consumer<OverviewResults>;
  updateRowsResults: Consumer<GuestRowResult[]>;
  incrementRejectedRows: Callback;
  incrementAcceptedRows: Callback;
  addRowResult: Consumer<GuestRowResult>;
  updateGuestsImportIsDone: Consumer<boolean>;
  setLoadedFileName: Consumer<string>;
}
