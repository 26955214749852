/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { TasksTablePageData } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TasksTableDataState = {
  pageData: Undefined<TasksTablePageData>;
};
const initialState: TasksTableDataState = {
  pageData: undefined,
};

const guestNeedTasksSlice = createSlice({
  name: "guest-need-tasks",
  initialState,

  reducers: {
    setTasksTableData: (state, action: PayloadAction<TasksTablePageData>) => {
      const draft = state;
      draft.pageData = action.payload;
    },
  },
});

export const { setTasksTableData } = guestNeedTasksSlice.actions;

export const { reducer: guestNeedTasksReducer } = guestNeedTasksSlice;
