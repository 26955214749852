/* eslint-disable no-param-reassign */

import type { TeamOperationTableSearchParams } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TeamOperationTableSearchParamsState = {
  params: TeamOperationTableSearchParams;
};

const initialState: TeamOperationTableSearchParamsState = {
  params: {
    page: 1,
    limit: 10,
    keyword: "",
  },
};
const teamOperationsSearchParamsSlice = createSlice({
  name: "team-perations-search-params",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.page = action.payload;
    },
    setPageLimit: (state, action: PayloadAction<number>) => {
      const draft = state;
      draft.params.limit = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.params.keyword = action.payload;
    },
    resetPageNumber: (state) => {
      const draft = state;
      draft.params.page = initialState.params.page;
    },
    resetParams: () => {
      return initialState;
    },
  },
});

export const { setPageNumber, setKeyword, setPageLimit, resetPageNumber, resetParams } =
  teamOperationsSearchParamsSlice.actions;

export const { reducer: teamOperationsSearchParamsReducer } = teamOperationsSearchParamsSlice;
