import type { Consumer } from "~utils/types";

export enum CategoriesPageTabsEnum {
  INCIDENTS = "incidents",
  INTERNAL_TASK = "internal_tasks",
  REQUEST = "requests",
}

export type CategoriesPageState = {
  currentTab: CategoriesPageTabsEnum;
};

export type IUseCategoriesPageState = () => {
  setCurrentTab: Consumer<CategoriesPageTabsEnum>;
};
