/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { ForgotPasswordPageModals, ForgotPasswordPageState } from "./types";
import { ForgotPasswordPageSteps } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ForgotPasswordPageState = {
  currentStep: ForgotPasswordPageSteps.USER_IDENTIFICATION,
  username: "",
  hotelPhone: "",
  currentModal: null,
};

const forgotPasswordPageSlice = createSlice({
  name: "forgot_password_page",
  initialState,

  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.username = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<ForgotPasswordPageSteps>) => {
      const draft = state;
      draft.currentStep = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<ForgotPasswordPageModals>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setHotelPhone: (state, action: PayloadAction<string>) => {
      const draft = state;
      draft.hotelPhone = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { setCurrentStep, setUsername, setCurrentModal, setHotelPhone, reset } = forgotPasswordPageSlice.actions;

export const { reducer: forgotPasswordPageReducer } = forgotPasswordPageSlice;
