import { isDefined } from "~utils/boolean-utils";
import { voidFunction } from "~utils/functions";

import { createHotelService, getHotel, getHotelLocations, getHotelServices, updateHotel } from "./repository";
import type {
  UseCreateHotelServiceType,
  UseGetHotelLocationsType,
  UseGetHotelServicesType,
  UseGetHotelType,
  UseUpdateHotelType,
} from "./types";

import { useMutation, useQuery } from "react-query";

export const useGetHotel: UseGetHotelType = ({
  id,
  isEnabled = true,
  onSuccessFn = voidFunction,
  onErrorFn = voidFunction,
}) => {
  return useQuery({
    enabled: isEnabled,
    queryKey: ["get-hotel", id],
    queryFn: async () => getHotel(id),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

/* eslint-disable max-params */
export const useGetHotelServices: UseGetHotelServicesType = (
  hotelId,
  isEnabled,
  filters,
  onSuccessFn = voidFunction,
  onErrorFn = voidFunction,
) => {
  return useQuery({
    enabled: isEnabled && isDefined(hotelId),
    queryKey: ["get-hotel-services", hotelId, filters],
    queryFn: async () => getHotelServices(hotelId, filters),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

export const useCreateHotelService: UseCreateHotelServiceType = (
  hotelId,
  onSuccessFn = voidFunction,
  onErrorFn = voidFunction,
) => {
  return useMutation({
    mutationKey: ["create-hotel-service", hotelId],
    mutationFn: async ({ dto, onUploadProgressFn }) => createHotelService(hotelId, dto, onUploadProgressFn),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

export const useGetHotelLocations: UseGetHotelLocationsType = (
  hotelId,
  isEnabled,
  onSuccessFn = voidFunction,
  onErrorFn = voidFunction,
) => {
  return useQuery({
    enabled: isEnabled && isDefined(hotelId),
    queryKey: ["get-hotel-locations", hotelId],
    queryFn: async () => getHotelLocations(hotelId),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};

export const useUpdateHotel: UseUpdateHotelType = (hotelId, onSuccessFn = voidFunction, onErrorFn = voidFunction) => {
  return useMutation({
    mutationKey: ["update-hotel", hotelId],
    mutationFn: async (dto: FormData) => updateHotel(hotelId, dto),
    onSuccess: onSuccessFn,
    onError: onErrorFn,
  });
};
