export const loginUrl = "auth/login";
export const logoutUrl = "auth/logout";
export const incidentsUrl = "incidents";
export const tasksUrl = "tasks";
export const employeesUrl = "employees";
export const stats = "stats";
export const operations = "operations";
export const categoriesUrl = "categories";
export const bookingUrl = "bookings";
export const staysUrl = "stays";
export const guestsUrl = "guests";
export const ordersUrl = "orders";
export const requestsUrl = "requests";
export const locationsUrl = "locations";
export const hotelsUrl = "hotels";
export const hotelServicesUrl = "hotelServices";
export const serviceCategoriesUrl = "serviceCategories";
export const conversationsUrl = "conversations";
export const forgetPasswordUrl = "auth/forgotPassword";
export const verificationCodeUrl = "auth/verificationCode";
export const countriesUrl = "countries";
export const citiesUrl = "cities";
export const teamsUrl = "teams";
export const rolesUrl = "roles";
export const TimezonesUrl = "timezones";
export const currenciesUrl = "currencies";
export const teamOperationCategoriesUrl = "team_operation_categories";
export const operationCategoriesUrl = "operation_categories";
export const teamChannelsUrl = "team_channels";
export const hotelChannelsUrl = "hotel_channels";
export const faqsUrl = "faqs";
export const faqCategoriesUrl = "faq_categories";
export const notificationsHistoryUrl = "notifications_history";
export const hotelRoomsUrl = "hotel_rooms";
export const subLocationsUrl = "sub_locations";
