import dynamic from "next/dynamic";
import type { ComponentType, PropsWithChildren } from "react";

export enum LayoutType {
  Default = "default",
  Login = "login",
}

export const layouts: Record<LayoutType, ComponentType<PropsWithChildren>> = {
  [LayoutType.Default]: dynamic(async () => import("./templates/defaultLayout")),
  [LayoutType.Login]: dynamic(async () => import("./templates/loginLayout")),
} as const;
