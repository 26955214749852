/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import type { GuestsPageModalsEnum, GuestsPageModalsState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: GuestsPageModalsState = {
  currentModal: null,
  detailsButtonAnchorId: null,
  selectedGuestId: null,
};

const guestPageModalsSlice = createSlice({
  name: "guestsPageModals",
  initialState,
  reducers: {
    setDetailsButtonAnchorId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.detailsButtonAnchorId = action.payload;
    },
    setCurrentModal: (state, action: PayloadAction<Nullable<GuestsPageModalsEnum>>) => {
      const draft = state;
      draft.currentModal = action.payload;
    },
    setGuestId: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.selectedGuestId = action.payload;
    },
    resetGuestsPageModalsState: (state) => {
      const draft = state;
      draft.currentModal = null;
      draft.detailsButtonAnchorId = null;
      draft.selectedGuestId = null;
    },
  },
});

export const { setDetailsButtonAnchorId, setCurrentModal, setGuestId, resetGuestsPageModalsState } =
  guestPageModalsSlice.actions;

export const { reducer: guestsPageModalsReducer } = guestPageModalsSlice;
