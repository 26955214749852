/* eslint-disable no-param-reassign */

import type { Undefined } from "~utils/types";

import type { FaqCategoriesList } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type FaqQuestionState = {
  data: Undefined<FaqCategoriesList>;
};

const initialState: FaqQuestionState = {
  data: undefined,
};

const faqQuestionsSlice = createSlice({
  name: "faq-questions",
  initialState,

  reducers: {
    setFaqQuestions: (state, action: PayloadAction<FaqCategoriesList>) => {
      const draft = state;
      draft.data = action.payload;
    },
  },
});

export const { setFaqQuestions } = faqQuestionsSlice.actions;

export const { reducer: faqQuestionsReducer } = faqQuestionsSlice;
