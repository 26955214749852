/* eslint-disable no-param-reassign */

import type { Nullable } from "~utils/types";

import { getNumberOfAppliedFilters } from "./selectors";
import type { GuestNeedType, GuestsFiltersStateStored, GuestsFiltersStored } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialFilters: GuestsFiltersStored = {
  room: null,
  stayStartsAt: null,
  stayEndsAt: null,
  guestNeedType: { BOOKING: false, ORDER: false, REQUEST: false },
  isIdentified: null,
};

const initialState: GuestsFiltersStateStored = {
  filters: initialFilters,
  appliedFilters: initialFilters,
  numberOfAppliedFilters: 0,
};

const guestsFiltersSlice = createSlice({
  name: "guetsFilters",
  initialState,

  reducers: {
    setGuestNeedType: (state, action: PayloadAction<GuestNeedType>) => {
      const draft = state;
      const isChecked = draft.filters.guestNeedType[action.payload];
      draft.filters.guestNeedType[action.payload] = !isChecked;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setRoom: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.room = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setStayStartsAt: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.stayStartsAt = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setStayEndsAt: (state, action: PayloadAction<Nullable<string>>) => {
      const draft = state;
      draft.filters.stayEndsAt = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    setIsIdentified: (state, action: PayloadAction<Nullable<boolean>>) => {
      const draft = state;
      draft.filters.isIdentified = action.payload;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
    },
    applyFilters: (state) => {
      const draft = state;
      draft.numberOfAppliedFilters = getNumberOfAppliedFilters(state.filters);
      draft.appliedFilters = draft.filters;
    },
    resetFilters: (state) => {
      const draft = state;
      draft.filters = { ...initialFilters };
      draft.numberOfAppliedFilters = 0;
    },
  },
});

export const {
  setGuestNeedType,
  setRoom,
  setStayEndsAt,
  setStayStartsAt,
  setIsIdentified,
  applyFilters,
  resetFilters,
} = guestsFiltersSlice.actions;

export const { reducer: guestsFiltersReducer } = guestsFiltersSlice;
